<template>
    <div class="page">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('消息详情')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="wrapper">
            <div class="mes-panel" v-if="info">
                <div class="tri-cell" style="font-size: 14px">
                        <div class="mes-content" style="text-align: left;padding:10px 0;color: #7A869A;" v-html="$t(info.content)"></div>
                </div>
                <div class="tri-cell" v-if="info.type == 1" style="border-top: 1px dashed rgba(255,255,255,.6)">
                    <div class="tri-row" v-if="info.mark != 2 && info.mark != 3">
                        <van-button type="info" class="robot-btn" @click="onClickSell(2, info.mark)">
                            <template>{{$t('接受')}}</template>
                        </van-button>
                        <van-button type="info" class="robot-btn" style="margin-left: 30px" @click="onClickSell(3, info.mark)">
                            <template>{{$t('拒绝')}}</template>
                        </van-button>
                    </div>
                    <div class="tri-row" v-else>
                        <div class="text" v-if="info.mark == 2">{{$t('已接受')}}</div>
                        <div class="text" v-if="info.mark == 3">{{$t('已拒绝')}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                id: 0,
                info: {},
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            getData() {
                this.$axios
                    .get('/user/message_info?id=' + this.id)
                    .then((res) => {
                        this.info = res.data.data;
                        console.log(this.info)
                    });
            },
            onClickSell(status, mark) {
                if (mark >= 2) {
                    this.$toast.fail(this.$t('你已经操作过该请求，请勿重复操作'));
                    return;
                }
                this.$axios
                    .post('/robot/sell_status?id=' + this.id + '&status=' + status + '&mr_id=' + this.info.ext_data.mr_id)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.$toast.success(res.data.msg);
                            this.getData();
                        } else {
                            this.$toast.fail(res.data.msg);
                        }
                    });
            }
        },
        created() {
            if (this.$route.query.id != undefined) {
                this.id = this.$route.query.id;
            }
            this.getData();
        }

    }
</script>
<style scoped>
    .robot-btn {
        font-weight: bold;
        font-size: 12px;
        width: 130px;
        height: 33px;
        margin: 0 10px;
    }
    .tri-row{
        display: flex;
        font-size: 18px;
        padding: 15px 0;
        justify-content: space-between;
    }
    .tri-row .text{
        color: #ffffff;
        background-color: #cccccc;
        padding: 5px 10px;
        border-radius: 10px;
    }
</style>